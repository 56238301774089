import firebase from 'firebase/compat/app'
import { getAuth } from "firebase/auth";


const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => firebase.auth().signInWithPopup(provider);

export function signUp(email, password) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export function signIn(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function resetPassword(emailAddress) {
  return firebase.auth().sendPasswordResetEmail(emailAddress);
}

export function signOut() {
  return firebase.auth().signOut();
}